import { initializeApp } from 'firebase/app'
import { getAnalytics } from "firebase/analytics";

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyD7iSgCNFMhuY3Xd0Ru8cCcIIBDGtvCbFQ",

    authDomain: "eloichr-8d7f4.firebaseapp.com",

    projectId: "eloichr-8d7f4",

    storageBucket: "eloichr-8d7f4.appspot.com",

    messagingSenderId: "1033373104004",

    appId: "1:1033373104004:web:e68747d23a29089b624fd1",

    measurementId: "G-JB8V64F282"


})

export const analytics = getAnalytics(firebaseApp);